import { WidthLimitation } from "@components/Content"
import classnames from "classnames"
import React from "react"
import { Flex, Text } from "rebass"
import { Botao } from "@xapps/design-system"

export function Wrapper({ isClear, children, ...props }) {
  return (
    <Flex
      alignItems="center"
      bg="primary"
      className={classnames({ "is-clear": isClear })}
      flexDirection="row"
      justifyContent="center"
      paddingX="3"
      width="100%"
      sx={{
        paddingY: [4, 5],
        "&.is-clear": {
          backgroundColor: "#262626",
        },
      }}
      {...props}
    >
      <WidthLimitation
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
      >
        {children}
      </WidthLimitation>
    </Flex>
  )
}

export function HeroHeading({ isClear, ...props }) {
  return (
    <Text
      as="h1"
      className={classnames({ "is-clear": isClear })}
      color="white"
      fontFamily="heading"
      fontWeight="light"
      lineHeight="1.06"
      sx={{
        maxWidth: [340, 600],
        fontSize: [5, 6],
        strong: {
          fontWeight: "bold",
        },
        "&::after": {
          content: "'.'",
          color: "secondary",
        },
        "&.is-clear": {
          color: "white",
        },
      }}
      {...props}
    />
  )
}

export function HeroSubtitle({ isClear, ...props }) {
  return (
    <Text
      as="span"
      className={classnames({ "is-clear": isClear })}
      color="white"
      fontSize="3"
      mt="3"
      sx={{
        fontWeight: "thin",
        "&.is-clear": {
          color: "white",
        },
      }}
      {...props}
    />
  )
}

export function BudgetButton(props) {
  return <Botao sx={{ marginTop: [3, 4], py: 3 }} marginBottom="6" {...props} />
}

export function HireSquadsButton(props) {
  return (
    <Botao
      marginTop="4"
      sx={{
        marginBottom: [0, 6],
        marginRight: [0, 3],
        py: 3,
      }}
      variant="terciario"
      {...props}
    />
  )
}

export function ButtonsWrapper(props) {
  return <Flex sx={{ flexDirection: ["column", "row"] }} {...props} />
}

export function RightSide(props) {
  return (
    <Flex
      sx={{
        height: "100%",
        mt: [3, 3, 0],
        width: ["100%", "100%", "auto"],
      }}
      alignItems="center"
      {...props}
    />
  )
}

export function LeftSide(props) {
  return <Flex flexDirection="column" {...props} />
}
